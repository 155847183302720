<template>
  <div class="page-books" v-if="product">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <span class="page-title">
          {{ $t('Books') }}
          <span class="page-subtitle ml-2">{{ product.title }}</span>
        </span>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <!-- <v-btn outlined color="primary">
          <i class="mr-1 fas fa-file-export"></i>
          {{ $t('Export') }}
        </v-btn>
        <v-btn outlined color="primary">
          <i class="fas fa-download mr-1"></i>
          {{ $t('Import') }}
        </v-btn> -->
        <v-spacer />
        <v-btn color="primary" @click="$refs.bookModal.show()">
          <i class="fas fa-plus mr-1"></i>
          {{ $t('Add book') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="elevation-1 mt-2 page__table-container">
      <v-col cols="12">
        <v-data-table
          :headers="columns"
          :items="books"
          :hide-default-header="false"
          :hide-default-footer="false"
          disable-pagination
          show-select
        >
          <template v-slot:[`item.code`]="{ item }">
            <a class="invent" @click="$router.push('/admin/books/' + item._id)">
              <i class="fas fa-book mr-1"></i>
              <span style="font-weight: 500;">{{ item.code }}</span>
            </a>
          </template>
          <template v-slot:[`item.numberOfPages`]="{ item }">
            <span>{{ item.pages ? item.pages.length : 0 }}</span>
          </template>
          <template v-slot:[`item.size`]="{ item }">
            <span>{{ (item.sizeWidth || 0) + ' x ' + (item.sizeHeight || 0) }}</span>
          </template>
          <template v-slot:[`item.pixel`]="{ item }">
            <span>{{ (item.sizeWidthPixel || 0) + ' x ' + (item.sizeHeightPixel || 0) }}</span>
          </template>
          <template v-slot:[`item.activated`]="{ item }">
            <span>
              <div v-if="!item.activated" class="unavailable">InActive</div>
              <div v-if="item.activated" class="available">Active</div>
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="$refs.bookModal.show(item._id)"><i class="fas fa-edit"></i></v-btn>
            <v-btn @click="onDelete(item._id)" icon v-if="!item.activated"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <BookModal ref="bookModal" :product="product._id" :onSaved="loadData" />
    <delete-action :content="`Book`" :title="`Delete`" />
  </div>
</template>

<script>
import moment from 'moment';
import { productApi } from '@/apis/product';
import { bookApi } from '@/apis/book';
import BookModal from './components/BookModal';

export default {
  components: { BookModal },
  data() {
    return {
      showDialogDelete: false,
      idItem: '',
      product: null,
      books: [],
      columns: [
        { text: 'CODE', value: 'code' },
        { text: 'PAGES', value: 'numberOfPages' },
        { text: 'SIZE INCH', value: 'size' },
        { text: 'SIZE PIXEL', value: 'pixel' },
        { text: 'ACTIVATED', value: 'activated' },
        { align: 'right', value: 'actions' },
      ],
    };
  },
  methods: {
    onDelete(item) {
      this.idItem = item;
      this.showDialogDelete = true;
    },
    async submitDelete() {
      try {
        await bookApi.deleteBook(this.idItem);
        await this.loadData();
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
    async loadProduct() {
      try {
        let res = await productApi.getById(this.$route.params.id);
        this.product = res.data.product;
        console.log(res);
      } catch (err) {
        this.product = null;
      }
      this.loadData();
    },
    async loadData() {
      if (!this.product) {
        this.books = [];
        return;
      }
      try {
        let res = await bookApi.getBooks({ product: this.product._id });
        this.books = res.data.books;
      } catch (err) {
        this.items = [];
      }
    },
  },
  created() {
    this.loadProduct();
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.page-subtitle {
  font-size: 0.5em;
}
.unavailable {
  background-color: #f7e7e6;
  color: #ee4b38;
  width: fit-content;
  border-radius: 3px 3px 3px 3px;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
}
.available {
  background-color: #f5fcff;
  color: #a98a57 !important;
  width: fit-content;
  border-radius: 3px 3px 3px 3px;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
}
</style>
