var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"720"},model:{value:(_vm.showed),callback:function ($$v) {_vm.showed=$$v},expression:"showed"}},[_c('v-card',{attrs:{"relative":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.model._id ? 'Update book' : 'Create book')+" ")]),_c('div',{staticClass:"close-icon",on:{"click":function($event){return _vm.clickCancel()}}},[_c('v-icon',[_vm._v("fas fa-times")])],1),_c('v-divider',{}),_c('v-card-text',[_c('v-container',{staticClass:"form-component"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("Code")]),_c('v-text-field',{attrs:{"hide-details":"","error-messages":_vm.invalidSubmit && !_vm.$v.model.code.required ? _vm.$t('Code is required') : '',"type":"text","name":"code"},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}})],1),_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-flex"},[_vm._v("Width (inch)")]),_c('v-text-field',{attrs:{"hide-details":"","type":"number","name":"sizeWidth"},model:{value:(_vm.model.sizeWidth),callback:function ($$v) {_vm.$set(_vm.model, "sizeWidth", $$v)},expression:"model.sizeWidth"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-flex"},[_vm._v("Height (inch)")]),_c('v-text-field',{attrs:{"hide-details":"","type":"number","name":"sizeHeight"},model:{value:(_vm.model.sizeHeight),callback:function ($$v) {_vm.$set(_vm.model, "sizeHeight", $$v)},expression:"model.sizeHeight"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-flex"},[_vm._v("Width in pixel")]),_c('v-text-field',{attrs:{"hide-details":"","type":"number","name":"sizeWidthPixel","error-messages":_vm.invalidSubmit && !_vm.$v.model.sizeWidthPixel.required
                    ? _vm.$t('Width in pixel is required')
                    : '' || (_vm.invalidSubmit && !_vm.$v.model.sizeWidthPixel.minValue)
                    ? _vm.$t('Minimum width in pixel is 0')
                    : ''},model:{value:(_vm.model.sizeWidthPixel),callback:function ($$v) {_vm.$set(_vm.model, "sizeWidthPixel", _vm._n($$v))},expression:"model.sizeWidthPixel"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-flex"},[_vm._v("Height in pixel")]),_c('v-text-field',{attrs:{"hide-details":"","type":"number","name":"sizeHeightPixel","error-messages":_vm.invalidSubmit && !_vm.$v.model.sizeHeightPixel.required
                    ? _vm.$t('Height in pixel is required')
                    : '' || (_vm.invalidSubmit && !_vm.$v.model.sizeHeightPixel.minValue)
                    ? _vm.$t('Minimum height in pixel is 0')
                    : ''},model:{value:(_vm.model.sizeHeightPixel),callback:function ($$v) {_vm.$set(_vm.model, "sizeHeightPixel", $$v)},expression:"model.sizeHeightPixel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('editor-new',{attrs:{"height":300,"dataT":_vm.model.description,"title":"Description"},on:{"update:dataT":function($event){return _vm.$set(_vm.model, "description", $event)},"update:data-t":function($event){return _vm.$set(_vm.model, "description", $event)}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"hide-details":"","name":"activated","label":"Active"},model:{value:(_vm.model.activated),callback:function ($$v) {_vm.$set(_vm.model, "activated", $$v)},expression:"model.activated"}})],1)],1)],1)],1)],1),_c('v-divider',{}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":""},on:{"click":function($event){return _vm.clickCancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.onSave()}}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }