var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.product)?_c('div',{staticClass:"page-books"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"page-header",attrs:{"cols":"12"}},[_c('span',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.$t('Books'))+" "),_c('span',{staticClass:"page-subtitle ml-2"},[_vm._v(_vm._s(_vm.product.title))])])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.bookModal.show()}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_vm._v(" "+_vm._s(_vm.$t('Add book'))+" ")])],1)],1),_c('v-row',{staticClass:"elevation-1 mt-2 page__table-container",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.books,"hide-default-header":false,"hide-default-footer":false,"disable-pagination":"","show-select":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"invent",on:{"click":function($event){return _vm.$router.push('/admin/books/' + item._id)}}},[_c('i',{staticClass:"fas fa-book mr-1"}),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(item.code))])])]}},{key:"item.numberOfPages",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.pages ? item.pages.length : 0))])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.sizeWidth || 0) + ' x ' + (item.sizeHeight || 0)))])]}},{key:"item.pixel",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.sizeWidthPixel || 0) + ' x ' + (item.sizeHeightPixel || 0)))])]}},{key:"item.activated",fn:function(ref){
var item = ref.item;
return [_c('span',[(!item.activated)?_c('div',{staticClass:"unavailable"},[_vm._v("InActive")]):_vm._e(),(item.activated)?_c('div',{staticClass:"available"},[_vm._v("Active")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.bookModal.show(item._id)}}},[_c('i',{staticClass:"fas fa-edit"})]),(!item.activated)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onDelete(item._id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)})],1)],1),_c('BookModal',{ref:"bookModal",attrs:{"product":_vm.product._id,"onSaved":_vm.loadData}}),_c('delete-action',{attrs:{"content":"Book","title":"Delete"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }