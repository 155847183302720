<template>
  <v-dialog persistent v-model="showed" width="720">
    <v-card relative>
      <v-card-title class="headline">
        {{ model._id ? 'Update book' : 'Create book' }}
      </v-card-title>
      <div class="close-icon" @click="clickCancel()">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-container class="form-component">
          <v-form>
            <v-row>
              <v-col cols="6">
                <label>Code</label>
                <v-text-field
                  hide-details
                  :error-messages="invalidSubmit && !$v.model.code.required ? $t('Code is required') : ''"
                  type="text"
                  name="code"
                  v-model="model.code"
                />
              </v-col>
              <v-col cols="6"></v-col>
              <!-- <v-col cols="6">
                <label class="d-flex">Number of page</label>
                <v-text-field
                  :error-messages="
                    invalidSubmit && !$v.model.numberOfPages.required ? $t('Number of page is required') : ''
                  "
                  type="number"
                  v-model="model.numberOfPages"
                />
              </v-col> -->
              <v-col cols="6">
                <label class="d-flex">Width (inch)</label>
                <v-text-field hide-details type="number" name="sizeWidth" v-model="model.sizeWidth" />
              </v-col>
              <v-col cols="6">
                <label class="d-flex">Height (inch)</label>
                <v-text-field hide-details type="number" name="sizeHeight" v-model="model.sizeHeight" />
              </v-col>
              <v-col cols="6">
                <label class="d-flex">Width in pixel</label>
                <v-text-field
                  hide-details
                  type="number"
                  name="sizeWidthPixel"
                  v-model.number="model.sizeWidthPixel"
                  :error-messages="
                    invalidSubmit && !$v.model.sizeWidthPixel.required
                      ? $t('Width in pixel is required')
                      : '' || (invalidSubmit && !$v.model.sizeWidthPixel.minValue)
                      ? $t('Minimum width in pixel is 0')
                      : ''
                  "
                />
              </v-col>
              <v-col cols="6">
                <label class="d-flex">Height in pixel</label>
                <v-text-field
                  hide-details
                  type="number"
                  name="sizeHeightPixel"
                  v-model="model.sizeHeightPixel"
                  :error-messages="
                    invalidSubmit && !$v.model.sizeHeightPixel.required
                      ? $t('Height in pixel is required')
                      : '' || (invalidSubmit && !$v.model.sizeHeightPixel.minValue)
                      ? $t('Minimum height in pixel is 0')
                      : ''
                  "
                />
              </v-col>
              <v-col cols="12">
                <editor-new :height="300" :dataT.sync="model.description" :title="`Description`" />

                <!-- <label class="d-flex">Description</label>
                <v-textarea type="text" name="description" v-model="model.description" /> -->
              </v-col>
              <v-col cols="12">
                <v-checkbox hide-details name="activated" label="Active" v-model="model.activated" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider class=""></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" @click="clickCancel()">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="onSave()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bookApi } from '@/apis/book';
import DataModal from '@/mixins/data-modal';
import { required, minValue } from 'vuelidate/lib/validators';
import EditorNew from '../../themes/components/EditorNew.vue';

export default {
  components: { EditorNew },
  data() {
    return {
      invalidSubmit: false,
      model: {
        code: '',
        // numberOfPages: '',
        sizeWidth: '',
        sizeHeight: '',
        sizeWidthPixel: '',
        sizeHeightPixel: '',
      },
    };
  },
  mixins: [DataModal],
  props: ['product'],
  validations: {
    model: {
      code: {
        required,
      },
      // numberOfPages: {
      //   required,
      // },
      sizeWidth: {
        required,
      },
      sizeHeight: {
        required,
      },
      sizeWidthPixel: {
        required,
        minValue: minValue(0),
      },
      sizeHeightPixel: {
        required,
        minValue: minValue(0),
      },
    },
  },
  methods: {
    clickCancel() {
      this.showed = false;
      this.invalidSubmit = false;
      this.$v.model.$reset;
    },
    async getModel(id) {
      try {
        let res = await bookApi.getBook(id);
        return res.data.book;
      } catch (err) {
        return null;
      }
    },
    async onSave() {
      this.$v.$touch();
      if (
        this.$v.model.code.$invalid ||
        this.$v.model.sizeWidthPixel.$invalid ||
        this.$v.model.sizeHeightPixel.$invalid
      ) {
        this.invalidSubmit = true;
      }
      let book = {
        code: this.model.code,
        // numberOfPages: parseInt(this.model.numberOfPages),
        sizeWidth: parseInt(this.model.sizeWidth),
        sizeHeight: parseInt(this.model.sizeHeight),
        sizeWidthPixel: parseInt(this.model.sizeWidthPixel),
        sizeHeightPixel: parseInt(this.model.sizeHeightPixel),
        description: this.model.description,
        activated: this.model.activated,
      };
      if (!this.invalidSubmit) {
        try {
          if (this.model._id) {
            await bookApi.updateBook(this.model._id, book);
          } else {
            book.product = this.product;
            await bookApi.createBook(book);
          }
          this.$parent.loadData();
          this.showed = false;
          return true;
        } catch (err) {
          this.errors = err;
          return false;
        }
      }
    },
  },
};
</script>
